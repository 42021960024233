(function () {
    'use strict';

    var traceViewerChart,
        zoomLimit = 1 / 1000000;

    $(function () {
        window.addEventListener("keydown", function (e) {
            if (traceViewerChart)
                if (e.keyCode == 37) //Check whether left arrow key is pressed
                    moveCursor(traceViewerChart, $("#movementType option:selected").val(), "moveToLeftMarker", traceViewerChart.chart.model.primaryXAxis.visibleRange, true);

                else if (e.keyCode == 39)  //Check whether right arrow key is pressed
                    moveCursor(traceViewerChart, $("#movementType option:selected").val(), "moveToRightMarker", traceViewerChart.chart.model.primaryXAxis.visibleRange);

        });
    });

    function moveCursor(traceViewer, movementType, marker, range, left) {
        var index = traceViewer.selectedCursor, change, condition;
        if (movementType == "Distance") {
            change = traceViewer.chart.model.annotations[index].x + (left ? -1 : 1) * parseInt($("#cursorDistance").val());
            condition = left ? (change >= range.min) : (change <= range.max);
            if (condition)
                traceViewer[("moveCursor" + (index === 0 ? "A" : "B"))](change, true);
        }
        else
            traceViewer[marker](traceViewer.traceOptions["cursor" + (index === 0 ? "A" : "B")], index);
    }

    angular
        .module('aerosApp')
        .directive('otdrTraceviewer', otdrTraceviewer);

    otdrTraceviewer.$inject = ['aerosApi', 'usSpinnerService', '$stateParams', '$rootScope', '$q', 'Notification',
        'UnitsMeasurementService', 'UserProfileService', 'TraceViewerService'];

    /* @ngInject */
    function otdrTraceviewer(aerosApi, usSpinnerService, $stateParams, $rootScope, $q, Notification,
                             UnitsMeasurementService, UserProfileService, TraceViewerService) {
        function link(scope, element, attrs, ngModel) {

            angular.extend(scope, {
                selectCursor: selectCursor,
                changeUnit: changeUnit,
                safeApply: safeApply,
                moveToMarker: moveToMarker,
                moveToFirstMarker: moveToFirstMarker,
                moveToLastMarker: moveToLastMarker,
                moveLeft: moveLeft,
                moveRight: moveRight,
                selectZoomLevel: selectZoomLevel,
                selectZoomType: selectZoomType,
                initModel: initModel,
                toggleView: toggleView,
                draw: draw,
                updateDetails: updateDetails
            });

            (function init(){
                scope.viewType = 'abCursor';
                scope.aCursor = {};
                scope.bCursor = {};
                scope.data = {
                    options: [
                        {id: 0, name: "Cursor A"},
                        {id: 1, name: "Cursor B"}
                    ],
                    selectedOption: {id: 0, name: "Cursor A"},
                    length: 1000,
                    units: [
                        {name: "m"},
                        {name: "km"},
                        {name: "ft"},
                        {name: "kft"},
                        {name: "mi"}
                    ],
                    selectedUnit: {name: "m"},
                    zoomType: [
                        {id: 'x', name: 'Horizontal'},
                        {id: 'y', name: 'Vertical'},
                        {id: 'x,y', name: 'Both'}
                    ],
                    selectedZoomType: {id: 'x,y', name: 'Both'},
                    movementTypes: [
                        {name: 'EventMarkers'},
                        {name: 'Distance'}
                    ],
                    selectedMovementType: {name: 'EventMarkers'},
                    zoomLevels: [
                        {name: 0.1, value: '10 %'}, {name: 0.2, value: '20 %'}, {name: 0.3, value: '30 %'},
                        {name: 0.4, value: '40 %'}, {name: 0.5, value: '50 %'}, {name: 0.6, value: '60 %'},
                        {name: 0.7, value: '70 %'}, {name: 0.8, value: '80 %'}, {name: 0.9, value: '90 %'},
                        {name: 0.8, value: '80 %'}, {name: 0.9, value: '90 %'}, {name: 1, value: '100 %'}],
                    zoomLevel: {name: 0.8, value: '80 %'}
                };

                scope.$on("$destroy", function () {
                    angular.element('#traceViewerChart_dataLabels').remove();
                });
            })();

            function selectCursor() {
                traceViewerChart.selectCursor(scope.data.selectedOption.id === 0 ? scope.model.cursorA : scope.model.cursorB);
            }

            function changeUnit() {
                traceViewerChart.changeUnit(scope.unit.SHORT);
            }

            function safeApply(fn) {
                if (!this.$root) return;

                var phase = this.$root.$$phase;
                if (phase == '$apply' || phase == '$digest') {
                    if (fn && $.isFunction(fn))
                        fn();
                } else
                    this.$apply(fn);
            }

            function moveToMarker(isRight) {
                var index = traceViewerChart.selectedCursor,
                    cursor = index === 0 ? scope.model.cursorA : scope.model.cursorB;

                traceViewerChart[("moveTo" + (isRight ? "Right" : "Left") + "Marker")](cursor, index);
                getEventsData(index);
            }

            function moveToFirstMarker(index) {
                var index = index || traceViewerChart.selectedCursor,
                    position = scope.model.markers[0].xAxisAdjusted;

                traceViewerChart[("moveCursor" + (index === 0 ? "A" : "B"))](position, true);
                getEventsData(index);
            }

            function moveToLastMarker(index) {
                var index = index || traceViewerChart.selectedCursor,
                    position = scope.model.markers[scope.model.markers.length - 1].xAxisAdjusted;

                traceViewerChart[("moveCursor" + (index === 0 ? "A" : "B"))](position, true);
                getEventsData(index);
            }

            function moveLeft() {
                var index = traceViewerChart.selectedCursor,
                    change = traceViewerChart.chart.model.annotations[index].x - scope.data.length;

                if (change >= traceViewerChart.chart.model.primaryXAxis.visibleRange.min)
                    traceViewerChart[("moveCursor" + (index === 0 ? "A" : "B"))](change, true);
            }

            function moveRight() {
                var index = traceViewerChart.selectedCursor,
                    change = traceViewerChart.chart.model.annotations[index].x + scope.data.length;

                if (change <= traceViewerChart.chart.model.primaryXAxis.visibleRange.max)
                    traceViewerChart[("moveCursor" + (index === 0 ? "A" : "B"))](change, true);
            }

            function selectZoomLevel() {
                var zoomFactor = parseFloat(scope.data.zoomLevel);

                if (zoomFactor > zoomLimit && zoomFactor < 1) {
                    traceViewerChart.chart.model.primaryXAxis.zoomFactor = zoomFactor;
                    traceViewerChart.chart.redraw(true);

                }
            }

            function selectZoomType() {
                traceViewerChart.chart.model.zooming.type = scope.data.selectedZoomType.id;
            }

            function getFiberConfigData() {
                return {
                    organizationId: $rootScope.orgId,
                    projectId: $stateParams.id,
                    fiberGroupId: $stateParams.fiberGroupId,
                    fiberId: $stateParams.fiberId
                }
            }

            function initModel() {

                angular.element('#traceViewerChart_dataLabels').remove();
                angular.element('#annotation_group_traceViewerChart').remove();

                function getLength() {
                    var length = 0;

                    scope.traceData.forEach(function (value) {
                        value.traceData.length > length ? length = value.traceData.length : false;
                    });
                    return length;
                }

                var markers = [], traces = [],
                    minX = scope.traceData[0].startX, maxX,
                    minY = scope.traceData[0].min, maxY = scope.traceData[0].max,
                    events = scope.eventsData.events;

                for( var m=0; m<events.length; m++) {
                    events[m].x = UnitsMeasurementService.metersTo(scope.unit.FULL, events[m].originalX);
                }

                events.sort(function (obj1, obj2) {
                    return obj1.x - obj2.x;
                });

                function getAdjustValue(wave, xForAdjust, index) {
                    var adjust = 0;
                    wave.traceData.forEach(function (val) {
                        var x = wave.startX + wave.deltaX * index;

                        if (typeof xForAdjust !== "undefined" && x <= xForAdjust) {
                            adjust = val;
                        }
                    });

                    return adjust;
                }

                // EVENTS
                events.forEach(function (value, key) {
                    var found = markers.some(function (element) {
                        if (element.eventId === value.eventId) {
                            element.backgroundColor = value.eventFailed ? 'red' : element.backgroundColor;
                            return true;
                        }
                        return false;
                    });

                    if (!found) {
                        markers.push({
                            xAxisPosition: value.originalX,
                            endEventX: value.endEventX,
                            xAxisAdjusted: value.x,
                            eventId: value.eventId,
                            number: (markers.length + 1) + "",
                            backgroundColor: value.eventFailed ? 'red' : 'green',
                            eventType: value.eventType,
                            eventFailed: value.eventFailed
                        })
                    }
                });

                // TRACE POINTS

                if (markers && markers.length > 0) {
                    var xForAdjust = markers[0].xAxisPosition;
                    var adjust = [];
                }

                scope.traceData.forEach(function (value, key) {
                    value.min < minY ? minY = value.min : false;
                    value.max > maxY ? maxY = value.max : false;
                    value.startX < minX ? minX = value.startX : false;
                    maxX = scope.traceData[0].startX + getLength() * scope.traceData[0].deltaX;

                    traces.push({
                        name: value.wavelength + ' Loss',
                        id: 'trace_' + value.wavelength,
                        shouldDisplay: true,
                        lineFormat: {
                            thickness: 2,
                            color: TraceViewerService.getWavelengthColors()[value.wavelength].color,
                            style: "solid"
                        }
                    });

                    var points = [];

                    adjust[key] = value.adjust; // frontend calculation disabled - scope.isAdjusted ? getAdjustValue(value, xForAdjust, key) : 0;

                    value.traceData.forEach(function (val, traceKey) {

                        var x = value.startX + value.deltaX * traceKey,
                            y = (-1 * val /*+ adjust[key]*/) / 1000;

                        points.push({
                            x: x,
                            y: y
                        })
                    });

                    traces[key].dataPoints = points;

                });

                var maxAdjust = Math.min.apply(null, adjust) || 0;

                minY += maxAdjust;
                maxY += maxAdjust;

                var cursorAInitXPosition = markers[0].endEventX,
                    cursorBInitXPosition = markers[markers.length - 1].endEventX;

                scope.primaryXAxisLabelCustom = function primaryXAxisLabelCustom(value) {
                    return Math.round(value * 1000) / 1000;
                };

                scope.primaryYAxisLabelCustom = function primaryYAxisLabelCustom(value) {
                    return -Math.round(value * 1000) / 1000;
                };

                scope.model = {
                    yAxis: {
                        caption: "Loss (dB)",
                        unit: "",
                        maxValue: maxAdjust > 0 ? maxAdjust / 1000 : 0,
                        minValue: (-1 * maxY + maxAdjust) / 1000,
                        tickMark: ((-1 * maxY) / 1000 - minY) / 10
                    },
                    xAxis: {minValue: minX, maxValue: maxX, caption: "Distance", unit: "m"},
                    shadedAreas: [
                        {start: minX, end: (minX + scope.eventsData.frontPanelLength), color: 'lightgrey'},
                        {
                            start: (minX + scope.eventsData.frontPanelLength),
                            end: (minX + scope.eventsData.frontPanelLength + scope.eventsData.launchCableLength),
                            color: 'yellow'
                        }, {
                            start: markers[markers.length - 1].xAxisPosition,
                            end: markers[markers.length - 1].xAxisPosition + scope.eventsData.receiveCableLength,
                            color: 'yellow'
                        }
                    ],
                    traces: traces,
                    markers: markers,
                    cursorA: {
                        id: "chartCursorA", xAxisPosition: cursorAInitXPosition, showDataLabels: true,
                        cursorFormat: {color: 'black', fillColor: 'blue', thickness: 1},
                        dataLabelsFormat: {fontColor: "black", fontSize: 12, fontWeight: 500, xOffset: 10, yOffset: 10}
                    },
                    cursorB: {
                        id: "chartCursorB",
                        xAxisPosition: cursorBInitXPosition,
                        showDataLabels: true,
                        cursorFormat: {color: 'red', fillColor: 'green', thickness: 1, style: "dashed"},
                        dataLabelsFormat: {fontColor: "black", fontSize: 12, fontWeight: 500, xOffset: 5, yOffset: 20}
                    },
                    cursorSelected: function (args) {
                        $("#selectedCursor").val(args.selectedCursor);
                    },
                    loadCompleted: function (args) {
                        $("#zoomFactor").val(args.zoomFactorX);
                    },
                    TraceViewerService: TraceViewerService,
                    getFiberConfigData: getFiberConfigData,
                    primaryXAxisLabelCustom: scope.primaryXAxisLabelCustom,
                    primaryYAxisLabelCustom: scope.primaryYAxisLabelCustom
                };

                scope.model.cursorMoved = function (args) {
                    scope.data.distance = parseFloat(args.difference);

                    setTimeout(function() {
                        scope.safeApply(function() {
                            updateDetails();
                            getEventsData();
                        })
                    }, 0);

                };

                scope.draw();
            }

            function toggleView() {
                scope.viewType = (scope.viewType === 'abCursor') ? 'eventData' : 'abCursor';
            }

            function draw() {
                angular.element(document).ready(function () {
                    traceViewerChart = angular.element("#traceViewerChart").TraceViewer(scope.model, zoomLimit);
                    traceViewerChart.changeUnit(scope.unit.SHORT);

                    scope.selectZoomType();
                    updateDetails();
                    getEventsData();
                });
            }

            function updateDetails() {
                if (typeof traceViewerChart === 'undefined') {
                    scope.aDistance = scope.model.cursorA.xAxisPosition;
                    scope.bDistance = scope.model.cursorB.xAxisPosition;
                }

                if (typeof traceViewerChart !== 'undefined' && typeof traceViewerChart.chart.model !== 'undefined') {
                    scope.aDistance = traceViewerChart.chart.model.annotations[0].x;
                    scope.bDistance = traceViewerChart.chart.model.annotations[1].x;

                    var cursorA = traceViewerChart.traceOptions.cursorA,
                        cursorB = traceViewerChart.traceOptions.cursorB;

                    scope.aCursor.values = [];
                    scope.bCursor.values = [];

                    var traces = traceViewerChart.traceOptions.traces;

                    for(var i in traces) {
                        scope.aCursor.values.push(
                            scope.primaryYAxisLabelCustom(traces[i]['cursorA'].labelValue.toFixed(3))
                        );

                        scope.bCursor.values.push(
                            scope.primaryYAxisLabelCustom(traces[i]['cursorB'].labelValue.toFixed(3))
                        );
                    }

                    if (scope.data.distance) {
                        var distance = scope.data.distance;
                    }

                    (function calculateValues() {
                        scope.calculatedValues = [];
                        var traces = traceViewerChart.traceOptions.traces;

                        for(var i in traces) {
                            var cA = scope.aCursor.values[i];
                            var cB = scope.bCursor.values[i];

                            var delta = cB - cA;
                            var convertedDistance = UnitsMeasurementService.toMeters(scope.unit.FULL, distance);

                            if (scope.converters.showUnitConverter) {
                                var convert = scope.converters.showUnitConverter;
                                scope.calculatedValues.push([ delta,
                                    convert(delta / parseFloat(convertedDistance) * 1000) ]);
                            } else {
                                scope.calculatedValues.push([ delta,
                                    delta / parseFloat(convertedDistance) * 1000 ]);
                            }
                        }
                    })();
                }
            }

            function getEventsData(index) {
                if (typeof traceViewerChart !== 'undefined') {
                    scope.currentEvents = [];

                    // Look at the selected cursor if we are not told which to use.
                    if(index === undefined){
                        index = traceViewerChart.selectedCursor;
                    }

                    // If the selected cursor is undefined then select the first cursor.
                    if(index === undefined){
                        index = 0;
                    }

                    var cursorA = traceViewerChart.chart.model.annotations[0].x,
                        cursorB = traceViewerChart.chart.model.annotations[1].x;

                    //Get all other Wavelength Events at the eventId
                    var testCursor = (index === 0) ? cursorA : cursorB;

                    // Find the closest event.
                    var minDelta = Number.MAX_VALUE;
                    var event = scope.eventsData.events[0];
                    for(var i = 0; i < scope.eventsData.events.length; i++){
                        var current = scope.eventsData.events[i];
                        if(Math.abs(testCursor - current.x) < minDelta){
                            minDelta = Math.abs(testCursor - current.x);
                            event = current;
                        }
                    }

                    //Get all events with the same eventId and push it on
                    if (typeof event !== 'undefined') {
                        scope.eventsData.events.forEach(function (value, key) {
                            if (event.eventId === value.eventId) {
                                scope.currentEvents.push(value);
                            }
                        });
                    }

                    scope.currentEvents = scope.currentEvents.sort(function (a, b) {
                        return a.wavelength - b.wavelength;
                    });

                    // decorate with wavelength color info
                    scope.currentEvents = scope.currentEvents.map(function(event) {
                        event.color = TraceViewerService.getWavelengthColors()[event.wavelength].color;
                        return event;
                    })
                }
            }
        }

        otdrTraceviewerController.$inject = ['$scope', '$filter', '$sce', 'TraceViewerService'];

        function otdrTraceviewerController($scope, $filter, $sce, TraceViewerService) {
            usSpinnerService.spin('spinner');

            angular.extend($scope, {
                fetchTraceViewerData: fetchTraceViewerData,
                getLossCaption: getLossCaption
            });

            var config = {
                    organizationId: $rootScope.orgId,
                    projectId: $stateParams.id,
                    fiberGroupId: $stateParams.fiberGroupId,
                    fiberId: $stateParams.fiberId
                };

            var userProfile = UserProfileService.get();

            $scope.config = config;
            $scope.unit = UnitsMeasurementService.getUnit(userProfile.user.preferredUnitOfMeasure);

            $scope.lossDistUnit = userProfile.user.preferredLossDistanceUnit;
            $scope.converters = UnitsMeasurementService.getConverters('dB/km|' + $scope.lossDistUnit);

            $scope.fetchTraceViewerData(true);

            function fetchTraceViewerData(isAdjusted) {
                TraceViewerService.getTraceViewerDataEvents(
                    $scope.config.organizationId,
                    $scope.config.projectId,
                    $scope.config.fiberGroupId,
                    $scope.config.fiberId,
                    isAdjusted
                ).then(function (response) {
                    $scope.traceData = response.traceData.data.traceData.sort(function (a, b) {
                        return a.wavelength - b.wavelength;
                    });
                    $scope.eventsData = response.events.data;
                    $scope.createdTmstp = response.traceData.data.createdOn;

                    $scope.initModel();
                }, function (error) {
                    switch (error.status) {
                        case 500: {
                            Notification.error({message: 'No trace data.'});
                            break
                        }
                        case 404: {
                            Notification.error({message: 'No trace data.'});
                            break
                        }
                    }
                }).finally(function () {
                    usSpinnerService.stop('spinner');
                });
            }

            function getLossCaption(event) {
                return $sce.trustAsHtml(
                    (event.isMTP ? "&asymp;&nbsp;" : "")
                    + $filter('number')(event.loss, 3));
            }
        }

        return {
            link: link,
            restrict: 'E',
            replace: true,
            templateUrl: '/static/templates/directive/otdr-traceviewer.html',
            controller: otdrTraceviewerController
        };
    }
}());
